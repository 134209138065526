import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { FormValidationErrorsComponent } from './form-validation-errors/form-validation-errors.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BecomeAgentComponent } from './become-agent/become-agent.component';
import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { InnerFooterComponent } from './inner-footer/inner-footer.component';
import { AlertComponent } from './alert/alert.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';


import { CookieService } from 'ngx-cookie-service';

//importing intercepters
import { ApiIntercepter, TokenInterceptor, HttpErrorInterceptor, ConsoleErrorIntercepter } from '../interceptors';

//importing guards
import { AuthGuard } from '../guards/auth-guard.service';

//importing guards
import { DateAgoPipe } from '../pipes/date-ago.pipe';

import { TwoDigitDecimaNumberDirective } from '../directives/two-digit-decima-number.directive';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = { };

@NgModule({
    imports: [
        RouterModule,
        CarouselModule ,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient],
            }
          }),
          ArchwizardModule,
          ChartsModule,
          AgmCoreModule.forRoot({
            // please get your own API key here:
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: 'AIzaSyAWoAEP98piUs9qs0A3a5H1mez7pjGf40w',
            libraries: ['places']
          }),
          ToastrModule.forRoot(),
          DropzoneModule,
          NgxPaginationModule,
          NgxMaskModule.forRoot(),
    ],
    declarations: [

        HeaderComponent,
        FooterComponent,
        FooterBottomComponent,
        FormValidationErrorsComponent,
        BreadcrumbsComponent,
        BecomeAgentComponent,
        LeftNavigationComponent,
        InnerFooterComponent,
        AlertComponent,
        PageLoaderComponent,
        DateAgoPipe,
        TwoDigitDecimaNumberDirective

    ],
    providers: [         
      AuthGuard, 
      CookieService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiIntercepter, multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor, multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor, multi: true
      },
      {
        provide: DROPZONE_CONFIG,
        useValue: DEFAULT_DROPZONE_CONFIG
      },
      {
        provide: ConsoleErrorIntercepter,
        useClass: ConsoleErrorIntercepter,
      }
    ],
    exports: [

        HeaderComponent,
        FooterComponent,
        FooterBottomComponent,
        FormValidationErrorsComponent,
        BreadcrumbsComponent,
        BecomeAgentComponent,
        LeftNavigationComponent,
        InnerFooterComponent,
        AlertComponent,
        PageLoaderComponent,
        DateAgoPipe,
        TwoDigitDecimaNumberDirective,


        CarouselModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ArchwizardModule,
        ChartsModule,
        AgmCoreModule ,
        ToastrModule,
        DropzoneModule,
        NgxPaginationModule,
        NgxMaskModule
    ]
})
export class SharedModule {
  constructor(translate: TranslateService) {
    // get the current UserLang
   // userLang = translate.getBrowserLang();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
   // translate.use(userLang);
  }
 }
