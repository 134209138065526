<section class="inner_page_breadcrumb">
    <div class="container">
        <div class="row">
        <div class="col-xl-6">
            <div class="breadcrumb_content">
            <ol class="breadcrumb">                


                <li class="breadcrumb-item" [ngClass]="{ 'active': last }" *ngFor="let breadcrumb of breadcrumbs; let first = first; let last = last">
                    <a *ngIf="!last" routerLink="{{ breadcrumb.link }}">
                        <i *ngIf="first" class="fa fa-home"></i>
                        {{ 'BREADCRUMB.'+breadcrumb.page | translate }}
                    </a>
                    <span *ngIf="last">{{ 'BREADCRUMB.'+breadcrumb.page | translate }}</span>
                    
                </li>              
            </ol>
            <!--<h4 class="breadcrumb_title">{{pageTitle}}</h4>-->
            </div>
        </div>
        </div>
    </div>
</section>