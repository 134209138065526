// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_ENDPOINT: 'https://www.tucasa.do',
  BLOG_API_ENDPOINT:'http://propertiesblogs.trigma.us/wp-json/wp/v2',
  APP_NAME: 'Tu Casa',
  PROPERTY_IMAGE_UPLOAD_API:'https://www.tucasa.do/api/property/uploadImage',

  PROPERTY_OPTIONS:{
    "RENT":"Renta",
    "SALE":"Compra"
  },
  "PROPERTY_STATUS":{
    "NEW":"Nuevo",
    "USED":"Usado",
    "IN-CONSTRUCTION":"En construcción"
  }
};

// export const environment = {
//   production: false,
//   API_ENDPOINT: 'http://localhost:3000',
//   BLOG_API_ENDPOINT:'http://propertiesblogs.trigma.us/wp-json/wp/v2',
//   APP_NAME: 'FindHouse',
//   PROPERTY_IMAGE_UPLOAD_API:'http://localhost:3000/api/property/uploadImage',
//
//   PROPERTY_OPTIONS:{
//     "RENT":"Rent",
//     "SALE":"Sale"
//   },
//   "PROPERTY_STATUS":{
//     "NEW":"New",
//     "USED":"Used",
//     "IN-CONSTRUCTION":"In Construction"
//   }
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
