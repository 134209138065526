<!-- Our Footer -->
<section *ngIf="!isAuthorizedPage" class="footer_one">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 pr0 pl0">
        <div class="footer_about_widget">
          <h4>{{ 'FOOTER.ABOUT-SITE' | translate }}</h4>
          <p>{{ 'FOOTER.ABOUT-SITE-CONTENT' | translate }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="footer_qlink_widget">
          <h4>{{ 'FOOTER.QUICK-LINKS' | translate }}</h4>
          <ul class="list-unstyled">
            <li><a routerLink="/quick-links/about-us">{{ 'HEADER.ABOUT' | translate }}</a></li>
            <li><a routerLink="/quick-links/terms">{{ 'FOOTER.TERMS-CONDITIONS' | translate }}</a></li>
            <li><a routerLink="/quick-links/contact-us">{{ 'FOOTER.SUPPORT-CENTER' | translate }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="footer_contact_widget">
          <h4>{{ 'FOOTER.CONTACT-US' | translate }}</h4>
          <ul class="list-unstyled">
            <li><a href="mailto:info@ctucasa.do">info@tucasa.do</a></li>
            <li><a href="#">+1 (809) 803-0603</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="footer_social_widget">
          <h4>{{ 'FOOTER.FOLLOW-US' | translate }}</h4>
          <ul class="mb30">
            <li class="list-inline-item"><a href="https://www.instagram.com/tucasa_do"><i class="fa fa-instagram"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
