<section class="start-partners bgc-thm pt50 pb50">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="start_partner tac-smd">
            <h2>{{ 'BECOMEAGENT.BECOME-REAL-ESTATE-AGENT' | translate }}</h2>
            <p>{{ 'BECOMEAGENT.WE-ONLY-WORK-WITH-BEST' | translate }}</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="parner_reg_btn text-right tac-smd">
            <a class="btn btn-thm2" routerLink="/authorization/signup">{{ 'BECOMEAGENT.REGISTER-NOW' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>