import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//importing guards
import { AuthGuard } from './core/guards/auth-guard.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },  
  { 
    path: 'home', 
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) 
  },
  { 
    path: 'properties', 
    loadChildren: () => import('./modules/properties/properties.module').then(m => m.PropertiesModule) 
  },
  { 
    path: 'quick-links', 
    loadChildren: () => import('./modules/quick-links/quick-links.module').then(m => m.QuickLinksModule) 
  },
  { 
    path: 'authorization', 
    loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule) 
  },
  { 
    path: 'authorized',
    canActivateChild: [AuthGuard], 
    loadChildren: () => import('./modules/authorized/authorized.module').then(m => m.AuthorizedModule) 
  },
  {
    path: '**',
    component: PageNotFoundComponent,   
    data: { title: 'Sorry!! Page Not Found.' }
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
