<!-- Our Error Page -->
<section class="our-error bgc-f7">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 offset-lg-1 text-center">
        <div class="error_page footer_apps_widget">
          <img class="img-fluid" src="assets/images/resource/error.png" alt="Error Picture">
          <div class="erro_code"><h1>{{ 'PAGENOTFOUND.PAGE-NOT-FOUND' | translate }}</h1></div>
          <p>{{ 'PAGENOTFOUND.LOOKING-FOR' | translate }}</p>          
        </div>
        <a class="btn btn_error btn-thm" routerLink="/">{{ 'PAGENOTFOUND.BACK-TO-HOME' | translate }}</a>
      </div>
    </div>
  </div>
</section>