import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";


import { SharedModule } from './core/components/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TitleService, AuthService, PageLoaderService, AlertService, UtilsService } from './core/services';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [  
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule    
    
  ],
  providers: [ 
    TitleService,
    AuthService,
    PageLoaderService,
    AlertService, 
    UtilsService    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
