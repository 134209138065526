import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
declare var $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isAuthorizedPage:boolean=false;
url:any='';
  constructor(private router: Router) {
    
    this.router.events.subscribe((event) => {
      
      if(event['url'] != undefined && (event['url']).includes('authorized')){
        this.isAuthorizedPage = true
      }
    });
   }

  ngOnInit() {
    $(window).scroll(function(){
        if ($(this).scrollTop() > 600) {
            $('.scrollToHome').fadeIn();
        } else {
            $('.scrollToHome').fadeOut();
        }
    });
    
    //Click event to scroll to top
    $('.scrollToHome').on('click',function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });
  }

}
