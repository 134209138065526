import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Justo ahora';
            const intervals = {
                'años': 31536000,
                'meses': 2592000,
                'semanas': 604800,
                'días': 86400,
                'horas': 3600,
                'minutos': 60,
                'segundos': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return `Hace ${counter} ${i.slice(0, -1)}`; // singular (1 day ago)
                    } else {
                        return `Hace ${counter} ${i}`; // singular (1 day ago)
                    }
            }
        }
        return value;
    }

}
