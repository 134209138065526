import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../core/services';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.css']
})
export class LeftNavigationComponent implements OnInit {

  constructor(private translateService: TranslateService, private router: Router, private utilsService:UtilsService) { }

  ngOnInit(): void {
  }

  //logout
  logout(){
    Swal.fire({
      title: 'Cerrar sesión',
      text: "Terminar la sesión actual y regresar a la vista principal",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.utilsService.logout()
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      }
    })
  }

}
