import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.css']
})
export class FooterBottomComponent implements OnInit {

  isAuthorizedPage = false;
  url: any = '';
  constructor(private router: Router) {

    this.router.events.subscribe((event) => {

      if(event['url'] != undefined && (event['url']).includes('authorized')){
        this.isAuthorizedPage = true
      }
    });
   }

  ngOnInit() {
  }

}
