<div class="dashboard_sidebar_menu dn-992">
    <ul class="sidebar-menu">
        <li class="header"><img src="assets/images/tucasa_logo.svg" alt="header-logo2.png">
          <span>  {{ 'HEADER.WEBSITE-TITLE' | translate }}</span>
        </li>
        <li class="title"><span>{{ 'LEFTNAVIGATION.MAIN' | translate }}</span></li>
        <li class="treeview"><a routerLink="/authorized/dashboard"><i class="flaticon-layers"></i><span> {{ 'LEFTNAVIGATION.DASHBOARD' | translate }}</span></a></li>

        <li class="title"><span>{{ 'LEFTNAVIGATION.MANAGE-LISTING' | translate }}</span></li>
        <li><a routerLink="/authorized/property-listing"><i class="flaticon-home"></i> <span> {{ 'LEFTNAVIGATION.MY-PROPERTY' | translate }}</span></a></li>
        <li><a routerLink="/authorized/featured-listing"><i class="flaticon-home"></i> <span> {{ 'LEFTNAVIGATION.FEATURED-PROPERTY' | translate }}</span></a></li>



        <li><a routerLink="/authorized/my-profile"><i class="flaticon-user"></i> <span>{{ 'HEADER.My-PROFILE' | translate }}</span></a></li>
        <li><a routerLink="/authorized/contact-request"><i class="flaticon-user"></i> <span>{{ 'HEADER.CONTACTS' | translate }}</span></a></li>
        <li><a (click)="logout()" href="javascript:void(0)"><i class="flaticon-logout"></i> <span>{{ 'HEADER.LOGOUT' | translate }}</span></a></li>
    </ul>
</div>


