import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  /*private dataSource = new BehaviorSubject<any>(false);
  data = this.dataSource.asObservable();*/

  
  constructor(private httpClient: HttpClient) { }


  

 

  login(postedData): Observable<any> {
    return this.httpClient
      .post('login', postedData, { observe: 'response' })
  
  }
  
  
  signUp(postedData): Observable<any> {
  
    return this.httpClient
        .post('signup', postedData,{ observe: 'response' })     
  
  }
  
  forgotPassword(postedData): Observable<any> {      
    return this.httpClient
        .post('forgot-password', postedData)
  
  }

  userProfileData(): Observable<any> {      
    return this.httpClient
        .post('userProfileData', { userID:localStorage.getItem('tucasa-user-data-ID')})
  
  }

  verifyToken(postedData): Observable<any> {      
    return this.httpClient
        .post('verifyToken', postedData)  
  }  

  processPostRequest(apiEndPoint, data){
    return this.httpClient
        .post(apiEndPoint, data)
  }
  
  processGetRequest(apiEndPoint){
    return this.httpClient
        .get(apiEndPoint)
  }
}

