import { Component, OnInit, ViewChild, ElementRef, NgZone  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivationEnd, ActivationStart, Router, RouterEvent } from "@angular/router";
import { Subscription } from 'rxjs/Subscription';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

//import custom validators
import { CustomValidators } from '../../custom-validators';
import { AuthService, UtilsService } from '../../../core/services';

declare var $;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild("loginSignupModal") loginSignupModal: ElementRef;
  loginForm: FormGroup;
  isSubmittedLoginForm:boolean = false
  destroy$: Subject<boolean> = new Subject<boolean>();

  signupForm: FormGroup;
  isSubmittedSignupForm:boolean = false
  isHomePage:boolean=false;
  loginSubscription: Subscription;
  isLoggedin: boolean = false;

  loggedinUserData:any = {} 
  navigationSubscription;
  constructor(private zone:NgZone, private translateService: TranslateService, private formBuilder: FormBuilder, private router: Router, private authService:AuthService, private utilsService:UtilsService) {  
     
    this.initalizeLoginForm()
    this.initalizeSignupForm()

    //url subscription
    this.router.events.subscribe((event) => {
      if(event['url'] != undefined && (event['url']!='/' || event['url']!='/home')){
        this.isHomePage = true
      }
    });
    


    

    this.navigationSubscription = this.router.events.subscribe((event: RouterEvent) => {
   
      if (event instanceof ActivationEnd) {
       
        if (localStorage.getItem('tucasa-auth-token')) {
        
          this.isLoggedin = true;          
          this.fetchLoggedinUserData();
        }else{
          this.isLoggedin = false;
        }
      }
    }); 
    
    this.utilsService.invokeHeaderComponent.subscribe((status:boolean) => {   
     if(status){
      this.fetchLoggedinUserData()
     }      
    });

  }

  resendEmail(){
    this.authService.processPostRequest('resendEmail',{userID:localStorage.getItem('tucasa-user-data-ID')}).pipe(takeUntil(this.destroy$)).subscribe((response) => { 
      this.utilsService.onSuccess(this.translateService.instant('ACTION-MESSAGE.RESEND-EMAIL'));
    })
  }

  

   
 
  mobileNavToggle() {
    if ($('#main-nav-bar .navbar-nav .sub-menu').length) {
        var subMenu = $('#main-nav-bar .navbar-nav .sub-menu');
        subMenu.parent('li').children('a').append(function () {
            return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
        });
        var subNavToggler = $('#main-nav-bar .navbar-nav .sub-nav-toggler');
        subNavToggler.on('click', function () {
            var Self = $(this);
            Self.parent().parent().children('.sub-menu').slideToggle();
            return false;
        });

    };   
}

  private fetchLoggedinUserData(){
    this.authService.userProfileData().pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.loggedinUserData = response
    });
  }

  navbarScrollfixed() {
    $('.navbar-scrolltofixed').scrollToFixed();
    var summaries = $('.summary');
    summaries.each(function(i) {
        var summary = $(summaries[i]);
        var next = summaries[i + 1];
        summary.scrollToFixed({
            marginTop: $('.navbar-scrolltofixed').outerHeight(true) + 10,
            limit: function() {
                var limit = 0;
                if (next) {
                    limit = $(next).offset().top - $(this).outerHeight(true) - 10;
                } else {
                    limit = $('.footer').offset().top - $(this).outerHeight(true) - 10;
                }
                return limit;
            },
            zIndex: 999
        });
    });
}

  ngOnInit() {     

    this.navbarScrollfixed()
    $('nav#menu').mmenu();
   
    this.mobileNavToggle()

    $("#respMenu").aceResponsiveMenu({
      resizeWidth: '768', // Set the same in Media query
      animationSpeed: 'fast', //slow, medium, fast
      accoridonExpAll: false //Expands all the accordion menu on click
    });


    if (localStorage.getItem('tucasa-auth-token')) {
      this.isLoggedin = true;          
      this.fetchLoggedinUserData();  
    }
  }

  

  //initalize login form
  private initalizeLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      remember_me: [null]
    });
  }

  //onsubmit login form
  onLoginSubmit() {
    if (this.loginForm.invalid) {

      this.isSubmittedLoginForm= true
      return false;
    }

    this.utilsService.showPageLoader(this.translateService.instant('ACTION-MESSAGE.CHECKING-AUTHORIZATION'));//show page loader
    this.authService.login(this.loginForm.value).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.utilsService.onSuccess(this.translateService.instant('ACTION-MESSAGE.LOGIN-SUCCESS'));
      
      localStorage.setItem('tucasa-user-data-ID', response.body._id)
      localStorage.setItem('tucasa-user-data', JSON.stringify(response.body))
      localStorage.setItem('tucasa-auth-token', response.headers.get('x-tucasa-auth-token'))   
      $(this.loginSignupModal.nativeElement).modal('hide');   
     // this.authService.isLoggedIn(true); 
      this.utilsService.hidePageLoader();//hide page loader
      this.router.navigate(['/authorized/dashboard']); 
    })
  }


  //initalize signup form
  private initalizeSignupForm() {
    this.signupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      password: [null, [Validators.required,Validators.minLength(10),Validators.maxLength(50)]],
      repassword: [null, [Validators.required, Validators.minLength(10),Validators.maxLength(50)]],
      type: ['AGENT'],
      terms:[null,[Validators.required]]
    },{
      // check whether our password and confirm password match
      validators: CustomValidators.passwordMatchValidator
    });
  }

  //onsubmit signup form
  onSignupSubmit() {
    if (this.signupForm.invalid) {
   
      this.isSubmittedSignupForm= true
      return false;
    }
    this.utilsService.showPageLoader(this.translateService.instant('ACTION-MESSAGE.SAVING-INFO'));//show page loader
    this.authService.signUp(this.signupForm.value).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.utilsService.onSuccess(this.translateService.instant('ACTION-MESSAGE.SIGNUP-SUCCESS'));
      
      localStorage.setItem('tucasa-user-data-ID', response.body._id)
      localStorage.setItem('tucasa-user-data', JSON.stringify(response.body))
      localStorage.setItem('tucasa-auth-token', response.headers.get('x-tucasa-auth-token'))      
      this.utilsService.hidePageLoader();//hide page loader
      $(this.loginSignupModal.nativeElement).modal('hide');  
      //this.authService.isLoggedIn(true); 
      this.router.navigate(['/authorized/dashboard']); 
    })
  }

  redirectToForgotPassword(){    
    $(this.loginSignupModal.nativeElement).modal('hide');
    this.router.navigate(['/authorization/forgot-password']);
  }
  //logout 
  logout(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'Your session will be end and redirect to home page!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout me!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {        
        this.utilsService.logout()        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }    
  }

}
