<div class="preloader"></div>
<!-- Main Header Nav -->


<header [ngClass]="{style2:isHomePage}" class="header-nav menu_style_home_one home4 navbar-scrolltofixed stricky main-menu slideIn animated scroll-to-fixed-fixed">
  <div class="container-fluid p0">
      <!-- Ace Responsive Menu -->
      <nav>
          <!-- Menu Toggle btn-->
          <div class="menu-toggle">
<!--              <img class="nav_logo_img img-fluid" src="assets/images/header-logo2.png" alt="header-logo.png">-->
              <button type="button" id="menu-btn">
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
              </button>
          </div>
          <a routerLink="/" class="navbar_brand float-left dn-smd">
              <img class="logo1 img-fluid" src="assets/images/tucasa_logo.svg" alt="header-logo.png">
              <img class="logo2 img-fluid" src="assets/images/tucasa_logo.svg" alt="header-logo2.png">
              <span class="website-name">{{ 'HEADER.WEBSITE-TITLE' | translate }}</span>
          </a>
          <!-- Responsive Menu Structure-->
          <!--Note: declare the Menu style in the data-menu-style="horizontal" (options: horizontal, vertical, accordion) -->
          <ul id="respMenu" class="ace-responsive-menu text-right" data-menu-style="horizontal">
              <li>
                  <a routerLink="/"><span class="title">{{ 'HEADER.HOME' | translate }}</span></a>
              </li>

              <li>
                  <a routerLink="/properties/listing"><span class="title">{{ 'HEADER.PROPERTIES' | translate }}</span></a>
              </li>

              <li>
                <a routerLink="/authorization/members-listing"><span class="title">{{ 'HEADER.MEMBERS' | translate }}</span></a>
              </li>

              <li>
                  <a routerLink="/quick-links/about-us"><span class="title">{{ 'HEADER.ABOUT' | translate }}</span></a>
              </li>

              <li>
                  <a routerLink="/quick-links/contact-us"><span class="title">{{ 'HEADER.CONTACT' | translate }}</span></a>
              </li>

              <li *ngIf="isLoggedin" class="user_setting">
                <div id="settingsection" class="dropdown">
                    <a class="btn dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown">

                      <img class="rounded-circle" src="{{loggedinUserData.profile_pic}}" onerror="this.src='/assets/images/team/e1.png'" alt="profile Image">
                      <span class="dn-1199">{{loggedinUserData?.name}}</span></a>
                    <div class="dropdown-menu">
                      <div class="user_set_header">
                        <img class="float-left" src="{{loggedinUserData.profile_pic}}"  onerror="this.src='/assets/images/team/e1.png'" alt="Profile Image">
                        <p>{{loggedinUserData?.name}} <br><span class="address">{{loggedinUserData?.email}}</span></p>
                      </div>
                      <div class="user_setting_content">
                      <a class="dropdown-item active" routerLink="/authorized/my-profile">{{ 'HEADER.My-PROFILE' | translate }}</a>
                      <a class="dropdown-item" routerLink="/quick-links/contact-us">{{ 'HEADER.HELP' | translate }}</a>
                      <a class="dropdown-item" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</a>
                      </div>
                    </div>
                </div>
              </li>

              <li *ngIf="!isLoggedin">
                <a href="javascript:void(0)" data-toggle="modal" data-target=".bd-example-modal-lg"><span class="title">{{ 'HEADER.LOGIN-SIGNUP' | translate }}</span></a>
              </li>

             <!-- <li *ngIf="!isLoggedin" class="last list-inline-item list_s"><a href="#" class="btn flaticon-user" data-toggle="modal" data-target=".bd-example-modal-lg"> <span class="dn-lg">{{ 'HEADER.LOGIN-SIGNUP' | translate }}</span></a></li>    -->

              <li *ngIf="isLoggedin" class="list-inline-item add_listing"><a routerLink="/authorized/add-property"><span class="flaticon-plus"></span><span class="dn-lg">{{ 'HEADER.CREATE-LISTING' | translate }}</span></a></li>
          </ul>
      </nav>
  </div>
  <div *ngIf="isLoggedin && loggedinUserData?.is_verified=='NOT-VERIFIED'" style="text-align:center;color:#ff5a5f">
    {{ 'HEADER.CONFIRM-ACCOUNT-1' | translate}} <a href="javascript:void(0)" (click)="resendEmail()" class="alert-link">{{ 'HEADER.CONFIRM-ACCOUNT-2' | translate}}</a> {{ 'HEADER.CONFIRM-ACCOUNT-3' | translate}}.
  </div>
</header>


<!-- Login/Signup Modal -->
<div class="sign_up_modal modal fade bd-example-modal-lg" #loginSignupModal id="loginSignupModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body container pb20">
            <div class="row">
              <div class="col-lg-12">
              <ul class="sign_up_tab nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ 'FORM-LABEL.LOGIN' | translate }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{ 'FORM-LABEL.REGISTER' | translate }}</a>
                </li>
            </ul>
              </div>
            </div>
        <div class="tab-content container" id="myTabContent">
            <div class="row mt25 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div class="col-lg-6 col-xl-6">
                <div class="login_thumb">
                  <img class="img-fluid w100" src="assets/images/resource/login.jpg" alt="login.jpg">
                </div>
              </div>
              <div class="col-lg-6 col-xl-6">
              <div class="login_form">
                <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                  <div class="heading">
                    <h4>{{ 'FORM-LABEL.LOGIN' | translate }}</h4>
                  </div>

                  <div class="input-group mb-2 mr-sm-2">
                      <input type="text" class="form-control" id="inlineFormInputGroupUsername2" formControlName="email" placeholder="{{ 'PLACEHOLDER.EMAIL' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="flaticon-user"></i></div>
                      </div>
                      <p class="errorCls"  *ngIf="loginForm.controls.email.touched || isSubmittedLoginForm">
                        <app-form-validation-errors errorPrefix="{{ 'FORM-LABEL.EMAIL' | translate }} " [errors]="loginForm.get('email').errors"></app-form-validation-errors>
                      </p>
                  </div>
                  <div class="input-group form-group">
                      <input type="password" class="form-control" formControlName="password"  id="exampleInputPassword1" placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="flaticon-password"></i></div>
                      </div>

                      <p class="errorCls" *ngIf="loginForm.controls.password.touched || isSubmittedLoginForm">
                        <app-form-validation-errors errorPrefix="{{ 'FORM-LABEL.PASSWORD' | translate }} " [errors]="loginForm.get('password').errors"></app-form-validation-errors>
                      </p>
                  </div>
                  <div class="form-group custom-control custom-checkbox">
                    <input type="checkbox" formControlName="remember_me" class="custom-control-input" id="exampleCheck1">
                    <label class="custom-control-label" for="exampleCheck1">{{ 'FORM-LABEL.REMEMBER-ME' | translate }}</label>
                    <a class="btn-fpswd float-right" href="javascript:void(0)" (click)="redirectToForgotPassword()">{{ 'FORM-LABEL.LOST-YOUR-PASSWORD' | translate }}</a>
                  </div>
                  <button [disabled]="!loginForm.valid" [ngClass]="{ 'submit-btn-disable': !loginForm.valid }"  type="submit" class="btn btn-log btn-block btn-thm">Log In</button>

                  <p class="text-center">{{ 'FORM-LABEL.NOT-HAVE-ACCOUNT' | translate }} <a  id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" class="text-thm" >{{ 'FORM-LABEL.REGISTER' | translate }}</a></p>
                </form>
              </div>
              </div>
            </div>
            <div class="row mt25 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div class="col-lg-6 col-xl-6">
                <div class="regstr_thumb">
                  <img class="img-fluid w100" src="assets/images/resource/regstr.jpg" alt="regstr.jpg">
                </div>
              </div>
              <div class="col-lg-6 col-xl-6">
              <div class="sign_up_form">
                <div class="heading">
                  <h4>{{ 'FORM-LABEL.REGISTER' | translate }}</h4>
                </div>
                <form [formGroup]="signupForm" (ngSubmit)="onSignupSubmit()">
                  <div class="form-group input-group">
                      <input type="text" formControlName="name" class="form-control" id="exampleInputName" placeholder="{{ 'PLACEHOLDER.NAME' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="flaticon-user"></i></div>
                      </div>
                      <p class="errorCls"  *ngIf="signupForm.controls.name.touched || isSubmittedSignupForm">
                        <app-form-validation-errors errorPrefix="{{ 'FORM-LABEL.NAME' | translate }} " [errors]="signupForm.get('name').errors"></app-form-validation-errors>
                      </p>
                  </div>
                  <div class="form-group input-group">
                      <input type="email" formControlName="email" class="form-control" id="exampleInputEmail2" placeholder="{{ 'PLACEHOLDER.EMAIL' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-envelope-o"></i></div>
                      </div>
                      <p class="errorCls"  *ngIf="signupForm.controls.email.touched || isSubmittedSignupForm">
                        <app-form-validation-errors errorPrefix="{{ 'FORM-LABEL.EMAIL' | translate }} " [errors]="signupForm.get('email').errors"></app-form-validation-errors>
                      </p>
                  </div>
                  <div class="form-group input-group">
                      <input type="password" formControlName="password" class="form-control" id="exampleInputPassword2" placeholder="{{ 'PLACEHOLDER.PASSWORD' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="flaticon-password"></i></div>
                      </div>
                      <p class="errorCls"  *ngIf="signupForm.controls.password.touched || isSubmittedSignupForm">
                        <app-form-validation-errors minLength=10 maxLength=50 errorPrefix="{{ 'FORM-LABEL.PASSWORD' | translate }} " [errors]="signupForm.get('password').errors"></app-form-validation-errors>
                      </p>
                  </div>
                  <div class="form-group input-group">
                      <input type="password" formControlName="repassword" class="form-control" id="exampleInputPassword3" placeholder="{{ 'PLACEHOLDER.REENTER-PASSWORED' | translate }}">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="flaticon-password"></i></div>
                      </div>
                      <p class="errorCls"  *ngIf="signupForm.controls.repassword.touched || isSubmittedSignupForm">
                        <app-form-validation-errors minLength=10 maxLength=50 errorPrefix="{{ 'FORM-LABEL.REPASSWORD' | translate }} " [errors]="signupForm.get('repassword').errors"></app-form-validation-errors>
                      </p>
                  </div>

                  <div class="form-group custom-control custom-checkbox">
                    <input formControlName="terms" type="checkbox" class="custom-control-input" id="exampleCheck2">
                    <label class="custom-control-label" for="exampleCheck2">{{ 'FORM-LABEL.TERMS-CHECKBOX' | translate }}</label>
                    <p class="errorCls"  *ngIf="signupForm.controls.terms.touched || isSubmittedSignupForm">
                      <app-form-validation-errors errorPrefix="{{'FORM-LABEL.ACCEPT-TERMS' | translate }} " [errors]="signupForm.get('terms').errors"></app-form-validation-errors>
                    </p>
                  </div>

                  <button [disabled]="!signupForm.valid" [ngClass]="{ 'submit-btn-disable': !signupForm.valid }"  type="submit" class="btn btn-log btn-block btn-thm">{{'FORM-LABEL.SIGNUP' | translate }}</button>

                  <p class="text-center">{{'FORM-LABEL.ALREADY-HAVE-ACCOUNT' | translate }} <a class="text-thm" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{'FORM-LABEL.LOG-IN' | translate }}</a></p>


                </form>
              </div>
              </div>
            </div>
        </div>
          </div>
      </div>
  </div>
</div>


<!-- Main Header Nav For Mobile -->
<div id="page" class="stylehome1 h0">
  <div class="mobile-menu">
    <div class="header stylehome1">
      <div class="main_logo_home2 text-center">
              <img class="nav_logo_img img-fluid mt20" src="assets/images/tucasa_logo.svg" alt="header-logo2.png">
              <span class="mt20">{{'HEADER.WEBSITE-TITLE' | translate }}</span>
      </div>
      <ul class="menu_bar_home2">
                <li class="list-inline-item list_s"><a routerLink="/authorization/signup"><span class="flaticon-user"></span></a></li>
        <li class="list-inline-item"><a href="#menu"><span></span></a></li>
      </ul>
    </div>
  </div><!-- /.mobile-menu -->

  <nav id="menu" class="stylehome1">
    <ul>
      <li><span><a routerLink="/">{{'HEADER.HOME' | translate }}</a></span></li>

      <li><span><a routerLink="/quick-links/about-us">{{'HEADER.ABOUT' | translate }}</a></span></li>

      <li><span><a routerLink="/properties/listing">{{'HEADER.PROPERTIES' | translate }}</a></span></li>
      <li><span><a routerLink="/authorization/members-listing">{{'HEADER.MEMBERS' | translate }}</a></span></li>

      <li><span><a target="_blank" href="http://propertiesblogs.trigma.us">{{'HEADER.BLOGS' | translate }}</a></span></li>

      <li><span><a routerLink="/quick-links/contact-us">{{'HEADER.CONTACT' | translate }}</a></span></li>


      <li class="mm-listitem" *ngIf="isLoggedin"><span class="mm-listitem__text"><a href="javascript:void(0)" (click)="logout()">{{'HEADER.LOGOUT' | translate }}</a></span></li>



      <li class="mm-listitem" *ngIf="!isLoggedin">
        <span class="mm-listitem__text"><a routerLink="/authorization">
          {{'FORM-LABEL.LOGIN' | translate }}
        </a></span>
      </li>

      <li class="mm-listitem" *ngIf="!isLoggedin">
        <span class="mm-listitem__text"><a routerLink="/authorization/signup">
          {{'FORM-LABEL.REGISTER' | translate }}
        </a></span>
      </li>

      <li  *ngIf="isLoggedin" class="cl_btn"><a class="btn btn-block btn-lg btn-thm circle flaticon-plus" routerLink="/authorized/add-property"> {{'HEADER.CREATE-LISTING' | translate }}</a></li>




    </ul>
  </nav>
</div>
