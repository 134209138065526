import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-agent',
  templateUrl: './become-agent.component.html',
  styleUrls: ['./become-agent.component.css']
})
export class BecomeAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
