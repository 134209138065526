<!-- Our Footer Bottom Area -->

<section *ngIf="!isAuthorizedPage" class="footer_middle_area pt40 pb40">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-xl-6">
        <div class="footer_menu_widget">
          <ul>
            <li class="list-inline-item"><a routerLink="/">{{ 'HEADER.HOME' | translate }}</a></li>
            <li class="list-inline-item"><a routerLink="/properties/listing">{{ 'FOOTER.LISTING' | translate }}</a></li>
            <li class="list-inline-item"><a routerLink="/quick-links/contact-us">{{ 'HEADER.CONTACT' | translate }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6">
        <div class="copyright-widget text-right">
          <p>{{ 'FOOTER.COPY-RIGHTS' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<a class="scrollToHome" href="#"><i class="flaticon-arrows"></i></a>
