import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() breadcrumbs: any[];

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }

}
