import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';//toaster class
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";

//import shared services
import { PageLoaderService } from './page-loader.service'
import { AuthService } from './auth.service'


@Injectable()
export class UtilsService {
  invokeHeaderComponent = new EventEmitter();

  sharedSearchData:any = {
    user_id: '',
    property_status:'',
    keyword:'',
    city:'',
    neighborhood:'',
    address:'',
    lat:'',
    lng:'',
    status:'',
    type:'',
    price_min:'',
    price_max:'',
    rooms:'',
    bathrooms:'',
    year_built:'',
    land_min_area:'',
    land_max_area:'',
    aminities:[],
    size:20,
    pageNumber:1,
    sort_by:'',
    sort_dir:'',
  };

  constructor(private httpClient: HttpClient, private pageLoaderService: PageLoaderService, private toastrManager: ToastrManager, private authService:AuthService, private translateService: TranslateService, private router:Router ) {
    //console.log('Yes in');
   }


  onAccountConfirm(status) {

    this.invokeHeaderComponent.emit(status);
  }
  /**
  * Show page loder on fetching data
  * @return void
  */
  public showPageLoader(message = ''):void{
    this.pageLoaderService.pageLoader(true);//show page loader
    if(message.length>0){
      this.pageLoaderService.setLoaderText('');//setting loader text
    }

  }

  /**
  * Hide page loder on fetching data
  * @return void
  */
  public hidePageLoader(): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text
  }

  /**
  * Show alert on success response & hide page loader
  * @return void
  */
  public onSuccess(message): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text empty
    this.toastrManager.successToastr(message, 'Success!'); //showing success toaster
  }

  /**
  * Show alert on error response & hide page loader
  * @return void
  */
  public onError(message): void {
    this.pageLoaderService.setLoaderText('');//setting loader text
    this.pageLoaderService.pageLoader(false);//hide page loader
    const error = message.message ? message.message : message;
    this.toastrManager.errorToastr(error, 'Lo sentimos, ha ocurrido un error',{maxShown:1});//showing error toaster message
  }

  /**
  * Logout user from the system and erase all info from localstorage
  * @return void
  */
  public logout():void{
    this.toastrManager.successToastr(this.translateService.instant('ACTION-MESSAGE.LOGOUT-SUCCESS'), 'Success!');//showing

    localStorage.clear();
    //this.authService.isLoggedIn(false);
    this.router.navigate(['/']);
  }

  /**
  * Check the user is loggedin oterwise redirect to login page
  * @return void
  */

  public checkAndRedirect(){
    if (localStorage.getItem("tucasa-auth-token")) {
      this.router.navigate(['/authorized/dashboard']);
    }
  }

  /**
  * To check the image validity for type jpeg, png, jpg
  * @return boolean
  * @param base64string image base64 string
  * @param type image type (jpeg, png, jpg)
  */
 public isFileCorrupted(base64string, type): boolean {

  if (type == 'png') {

    const imageData = Array.from(atob(base64string.replace('data:image/png;base64,', '')), c => c.charCodeAt(0))
    const sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]; // in hex:

    //check last 12 elements of array so they contains needed values
    for (let i = 12; i > 0; i--) {
      if (imageData[imageData.length - i] !== sequence[12 - i]) {
        return false;
      }
    }

    return true;
  }
  else if(type=='pdf'){
    return true;
  }
  else if (type == 'jpeg' || type == 'jpg') {
    const imageDataJpeg = Array.from(atob(base64string.replace('data:image/jpeg;base64,', '')), c => c.charCodeAt(0))
    const imageCorrupted = ((imageDataJpeg[imageDataJpeg.length - 1] === 217) && (imageDataJpeg[imageDataJpeg.length - 2] === 255))
    return imageCorrupted;
  }
}
  /**
  * Remove Image from AWS Bucket
  * @return boolean
  */
 public removePropertyImageFromBucket(params): Observable<any> {
    return this.httpClient
      .post('property/deletePropertyImage', params)
  }


  /**
  * Post the data and endpoint
  */
  processPostRequest(apiEndPoint, data){
    return this.httpClient
        .post(apiEndPoint, data)
  }
  /**
  * Get the data using posted endpoint
  */
  processGetRequest(apiEndPoint){
    return this.httpClient
        .get(apiEndPoint)
  }

  public setSearchFormData(value){

    this.sharedSearchData = value /*{
      user_id: value.user_id || null,
      property_status:value.property_status || null,
      keyword:value.keyword || null,
      city:value.city || null,
      neighborhood:value.neighborhood || null,
      address:value.address || null,
      lat:value.lat || null,
      lng:value.lng || null,
      status:value.status || null,
      type:value.type || null,
      price_min:value.price_min || null,
      price_max:value.price_max || null,
      rooms:value.rooms || null,
      bathrooms:value.bathrooms || null,
      year_built:value.year_built || null,
      land_min_area:value.land_min_area || null,
      land_max_area:value.land_max_area || null,
      aminities:value.aminities || [],
      size:value.size || 20,
      pageNumber:value.pageNumber || 1,
      sort_by:value.sort_by || '',
      sort_dir:value.sort_dir || '',
    }*/

   // localStorage.setItem('sharedSearchData', JSON.stringify(value))
  }

  public getSearchFormData(){

   return  this.sharedSearchData

  }
}
